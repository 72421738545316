import { __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, onMounted, ref } from "vue";
import router from "@/router/index";
import { useRoute } from "vue-router";
import { productTypeApi, productByTypeApi } from "@/api/product";
import HomeJumbotron from "@/views/home/homeJumbotron/index.vue";
import Dialog from "@/components/dialog/index.vue";
import Nav from "@/components/nav/nav.vue";
import { sendMailApi } from "@/api/email";
import BasisGroup from "@/views/basisGroup/index.vue";
export default defineComponent({
    components: { BasisGroup: BasisGroup, Dialog: Dialog, Nav: Nav, HomeJumbotron: HomeJumbotron },
    setup: function () {
        var _this = this;
        var route = useRoute();
        document.documentElement.scrollTop = 0;
        var navActiveIndex = ref("2-1");
        var productTypeList = ref("");
        var productList = ref("");
        var selectProductTypeName = ref("");
        var selectProductId = ref(1);
        //模态框调用
        var dialogVisible = ref(false);
        var content = ref("");
        var sendMail = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, sendMailApi({
                            cc: [],
                            content: content.value,
                            subject: "来自因格艾官方网站产品咨询",
                            to: "services@yingeai.com"
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var tipShow = function () {
            dialogVisible.value = true;
        };
        var close = function (typeName, submitConsultData, phone, email) {
            if ((typeName != null && submitConsultData != null) && (phone != null || email != null)) {
                content.value = "----咨询产品名称:" + typeName + "----咨询项目:" + submitConsultData.substring(2, submitConsultData.length - 2) + "----email地址:" + email + "----手机号码:" + phone;
                //提交
                sendMail();
            }
            //TODO 取消
            dialogVisible.value = false;
            console.log(dialogVisible.value, "close-props");
        };
        //接收参数
        var acceptProductId = route.query.id;
        var acceptProductName = route.query.productName;
        if (route.query.id) {
            selectProductId.value = Number(acceptProductId);
        }
        if (route.query.productName) {
            selectProductTypeName.value = acceptProductName;
        }
        //获取产品类型列表
        var getProductTypeList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = productTypeList;
                        return [4 /*yield*/, productTypeApi()];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        //根据类型获取产品列表
        var getProductList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = productList;
                        return [4 /*yield*/, productByTypeApi({
                                productTypeId: selectProductId.value,
                            })];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var JumpProductDetails = function (item) {
            var productInfo = JSON.stringify(item);
            router.push({
                name: "productDetails",
                query: { productInfo: encodeURIComponent(productInfo) },
            });
        };
        var selectProduct = function (id) {
            selectProductId.value = Number(id);
            getProductList();
            selectProductTypeName.value = productTypeList.value[id - 1].typeName;
        };
        onMounted(function () {
            getProductTypeList();
            getProductList();
        });
        return {
            JumpProductDetails: JumpProductDetails,
            selectProduct: selectProduct,
            navActiveIndex: navActiveIndex,
            productTypeList: productTypeList,
            productList: productList,
            selectProductTypeName: selectProductTypeName,
            selectProductId: selectProductId,
            dialogVisible: dialogVisible,
            tipShow: tipShow,
            close: close,
        };
    },
});
